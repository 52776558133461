import StoreCountry from 'GlobalStores/Cores/County/StoreCountry'
import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'
import StoreFormat from 'GlobalStores/Format/StoreFormat'
import StoreReferral from 'GlobalStores/Referral/StoreReferral'
import StoreProducts from 'GlobalStores/StoreProducts'
import StoreAuth from 'GlobalStores/User/StoreAuth'
import StoreUser from 'GlobalStores/User/StoreUser'
import { getOSName } from 'Helpers/Bowser'
import { ObjectHelpers } from 'Helpers/ObjectHelpers'
import { get, isEmpty } from 'lodash'
import { makeAutoObservable, runInAction, toJS } from 'mobx'
import { FeelGreatAPI } from 'Services/FeelGreat/APIs'
import { FormatAPIs } from 'Services/FeelGreat/FormatAPIs'
import { isNothing } from 'Utils/Utils'
import Checkout from './Country/Checkout'
import storeCredit from 'GlobalStores/StoreCredit'
import { useTranslate } from 'GlobalStores/Cores/Translator/useTranslate'
import storeCountry from 'GlobalStores/Cores/County/StoreCountry'
import storeFreightCharge from './StoreFreightCharge'
import storeFormAddress from 'GlobalStores/Checkout/StoreFormAddress'

const PRODUCT_PRICE = {
    AFTER: 'after',
    BEFORE: 'before',
    RETAIL: 'retail',
    MEMBER: 'member'
}

class StoreCheckout {
    paymentLoading = false
    selectedItem = ''
    selectedPurchaseOption = ''

    useReferralPrice = false
    useMemberPrice = false

    checkoutInstance = null

    cart = {}

    forms = []
    shippingForms = []

    shippingMethod = null
    shippingPrice = 0
    shippingPriceWithoutMethod = 0
    shippingPaymentOption = null

    vat = 0

    creditCard = []
    creditCardValue = []

    isReadyToPlaceOrder = false

    completedActionsLink = []

    uuid = ''

    checkoutErrors = []
    duplicatedEmail = false
    duplicatedPhone = false

    passwordDidNotMatch = false
    shippingMethodIsRequired = false

    checkoutLoading = false
    checkoutPreparing = true
    dataReady = false

    useBillingAddressSameAsShipping = true
    billingAddressForm = null

    autoAddressSearchText = ''

    period = ''

    orderSpec = null
    orderSpecError = []

    paymentCybersourceData = {
        ba_id: '',
        gateway_error_url: '',
        gateway_name: '',
        gateway_success_url: '',
        source_path: '',
        token: '',
        type: '',
        uuid: ''
    }

    isLoginToPurchase = false

    IsLoginOnly = false

    translate = useTranslate()

    noSignupWithoutReferral = ['JP']
    showLoginModal = false
    // World Pay
    ddcHTML = null
    sessionId = null
    challengeHTML = null
    worldPayResponse = false
    countPathChange = 0
    dimension = '390x400'
    isShowSameDayDelivery = false

    paymentErrors = []
    createCustomerLoading = false

    Init(data) {
        Object.keys(data).map(key => {
            runInAction(() => {
                if (key === 'shippingAddress') {
                    if (this.CheckOutInstance().CustomShippingForms()) {
                        this.shippingForms = this.CheckOutInstance().CustomShippingForms()
                    } else {
                        this.shippingForms = data[key].inputs
                    }

                    if (this.CheckOutInstance().CustomCreateAccountWithShippingForms()) {
                        this.forms = this.CheckOutInstance().CustomCreateAccountWithShippingForms()
                    } else {
                        this.forms = data[key].inputs
                    }
                }
                if (key === 'order') {
                    this.orderSpec = data[key]
                }
                if (key === 'is_show_same_day_delivery') {
                    this.isShowSameDayDelivery = data[key] ? data[key] : false
                }
            })

            return true
        })
    }

    getPaymentCybersourceData() {
        return this.paymentCybersourceData
    }

    setPaymentCybersourceData(value) {
        Object.keys(value).map(key => {
            runInAction(() => {
                this.paymentCybersourceData[key] = value[key]
            })
        })
    }

    SetSelectedItem(value) {
        runInAction(() => {
            this.selectedItem = value
        })
    }

    GetSelectedItem() {
        return this.selectedItem
    }

    SetShippingMethod(shippingMethod, step = '') {
        this.shippingMethod = shippingMethod
        this.CalculateCart(step)
    }

    GetShippingMethod() {
        return this.shippingMethod
    }

    GetShippingPrice() {
        return this.shippingPrice
    }

    GetShippingPriceWithoutMethod() {
        return this.shippingPriceWithoutMethod
    }

    GetVatPrice() {
        return this.vat
    }

    GetShippingPaymentOption() {
        return this.shippingPaymentOption
    }

    SetShippingPaymentOption(value) {
        return (this.shippingPaymentOption = value)
    }

    SetSelectedPurchaseOption(value) {
        runInAction(() => {
            this.selectedPurchaseOption = value
        })
    }

    GetSelectedPurchaseOption() {
        // if (this.selectedPurchaseOption.length === 0) {
        //     this.selectedPurchaseOption = 'subscribe'
        // }
        return this.selectedPurchaseOption
    }

    SetUseReferralPrice(value) {
        runInAction(() => {
            this.useReferralPrice = value
        })
    }

    SetUseMemberPrice(value) {
        runInAction(() => {
            this.useMemberPrice = value
        })
    }

    GetUseReferralPrice() {
        return this.useReferralPrice
    }

    GetUseMemberPrice() {
        return this.useMemberPrice
    }

    GetTypeOfProductPrice() {
        if (this.useMemberPrice || this.useReferralPrice) {
            return PRODUCT_PRICE.AFTER
        } else {
            return PRODUCT_PRICE.RETAIL
        }
    }

    GetBillingAddressForm() {
        let arrange = ['street_address', 'city', 'state', 'zip_code', 'phone', 'country']

        if (!this.billingAddressForm && this.useBillingAddressSameAsShipping === false) {
            let data = toJS(this.GetCheckoutForms())
            let reArragedBillingAddressForm = []
            arrange.map(key => {
                let item = data.find(field => field.key === key)
                if (item) {
                    reArragedBillingAddressForm.push(item)
                }
            })

            data = reArragedBillingAddressForm

            this.billingAddressForm = []
            data.map(item => {
                if (item.key !== 'country') {
                    item.value = ''
                }
                if (item.key === 'email' || item.key === 'password') return item
                this.billingAddressForm.push(item)
                return item
            })
        } else if (this.billingAddressForm && this.useBillingAddressSameAsShipping === true) {
            runInAction(() => (this.billingAddressForm = null))
        }

        return this.billingAddressForm
    }

    SetBillingAddressForm(key, value) {
        if (this.GetBillingAddressForm()) {
            this.GetBillingAddressForm().map(item => {
                if (item.key === key) {
                    item.value = value
                }

                return item
            })
        }
    }

    HasDataInBillingAddressForm() {
        if (this.useBillingAddressSameAsShipping) {
            return true
        } else if (this.useBillingAddressSameAsShipping === false && this.billingAddressForm) {
            let data = []
            this.billingAddressForm.map(item => {
                if (item.value.length === 0) {
                    data.push(item.key)
                }

                return item
            })

            return data.length === 0 ? true : false
        } else {
            return true
        }
    }

    async RestoreCart(restoreData = {}) {
        this.cart = restoreData
        return this.cart
    }

    async SetOrderSpecError(value = []) {
        return (this.orderSpecError = value)
    }
    async GetOrderSpecError() {
        return this.orderSpecError
    }

    async CheckCartLimit(flavor, qty, purchaseType) {
        const { cart_limit_price, cart_limit_qty, cart_warning_qty_total, cart_warning_price_total } = this.orderSpec

        const lang = StoreTranslate.IsEnglish() ? 'english' : 'native'
        const cart = this.cart
        this.orderSpecError = []

        // Special if it just first time add Item to cart
        if (!cart || isEmpty(cart)) {
            return false
        }

        let cartLimitPrice = parseInt(cart_limit_price[lang])
        let cartLimitQty = parseInt(cart_limit_qty[lang])
        // let cartLimitTotalQty = parseInt(cart_limit_qty_total[lang])

        let totalPrice = this.TotalPrice() || 0
        let price = StoreProducts.GetProductPrice(flavor, purchaseType)

        let nextTotalPrice = totalPrice

        // Case limit by price
        if (!isNothing(cartLimitPrice)) {
            if (qty < 0) {
                nextTotalPrice -= price
            } else {
                nextTotalPrice += price
            }
            // checkStep 1 Total price
            if (cartLimitPrice < nextTotalPrice) {
                let warnTxt = `Total price limit is ${StoreCountry.GetCurrencySymbol()}${cart_limit_price[lang]}`

                if (cart_warning_price_total[lang] !== '') {
                    warnTxt = cart_warning_price_total[lang]
                }

                this.orderSpecError.push(warnTxt)
                return true
            }
        }

        if (!cart[purchaseType] || !cart[purchaseType][flavor]) {
            return false
        }

        let itemQty = Number(cart[purchaseType][flavor])
        let nextItemQty = itemQty
        // Case limit by quantity
        if (!isNothing(cartLimitQty)) {
            nextItemQty = nextItemQty + qty
            if (cartLimitQty < nextItemQty) {
                let warnTxt = `Total quantity limit is ${cart_limit_qty[lang]}`

                if (cart_warning_qty_total[lang] !== '') {
                    warnTxt = cart_warning_qty_total[lang]
                }
                this.orderSpecError.push(warnTxt)
                return true
            }
        }
        return false
    }

    async AddToCart(flavor, qty, purchaseType) {
        const hasPurchaseType = this.cart[purchaseType] ? true : false
        const hasPurchaseTypeAndFlavor = hasPurchaseType ? (this.cart[purchaseType][flavor] !== undefined ? true : false) : false

        if (hasPurchaseType) {
            if (hasPurchaseTypeAndFlavor) {
                if (qty > 0) {
                    this.cart[purchaseType][flavor] += qty
                } else if (qty < 0 && this.cart[purchaseType][flavor] > 0) {
                    this.cart[purchaseType][flavor] += qty
                }
            } else {
                this.cart[purchaseType][flavor] = qty
            }
        } else {
            this.cart[purchaseType] = {
                [flavor]: qty
            }
        }
    }

    async RemoveItemInCart(purchaseType, flavor, step) {
        const item = get(this.cart, `[${purchaseType}][${flavor}]`, null)
        if (item) {
            delete this.cart[purchaseType][flavor]
        }

        if (this.cart[purchaseType]) {
            if (ObjectHelpers.isEmpty(this.cart[purchaseType])) {
                delete this.cart[purchaseType]
            }
        }
        await this.UpdateQty()
        this.CalculateCart(step)
    }

    GetCart() {
        return this.cart
    }

    SubTotal() {
        let subTotal = 0
        Object.keys(this.cart).map(purchaseType => {
            Object.keys(this.cart[purchaseType]).map(flavor => {
                subTotal += this.cart[purchaseType][flavor] * StoreProducts.GetProductPrice(flavor, purchaseType)
                return false
            })
            return false
        })

        return subTotal
    }

    GetFinalARBalance() {
        const allowCreditEnable = StoreAuth.IsAllowCredit()
        const isHideZero = get(StoreAuth, `allowCredit[${storeCredit.creditType}].isHideZero`, false)
        let useARbalance = 0

        if (
            StoreAuth.IsAuthorized() &&
            ((allowCreditEnable === true && isHideZero !== true) ||
                (allowCreditEnable === true && isHideZero === true && storeCredit.GetCreditData()?.availableBalance > 0))
        ) {
            const orderSummaryConf = this.CheckOutInstance().GetOrderSummaryConfig()
            let productCredit = toJS(orderSummaryConf).productCredit

            if (productCredit && productCredit.allowToUse.length) {
                let cred = productCredit.allowToUse.find(allow => {
                    return allow.status === StoreAuth.userStatus
                })
                if (cred) {
                    useARbalance = cred.value * this.TotalPriceIncludeShipping()
                }
            } else {
                useARbalance = storeCredit.GetCreditData()?.creditUsed
            }
            if (useARbalance > storeCredit.GetCreditData()?.availableBalance) {
                useARbalance = storeCredit.GetCreditData()?.availableBalance
            }
        }

        return useARbalance
    }

    TotalPriceIncludeShipping() {
        let totalPrice = 0

        Object.keys(this.cart).map(purchaseType => {
            Object.keys(this.cart[purchaseType]).map(flavor => {
                totalPrice += this.cart[purchaseType][flavor] * StoreProducts.GetProductPrice(flavor, purchaseType)
                return false
            })
            return false
        })
        return totalPrice + this.shippingPrice + this.shippingPriceWithoutMethod
    }

    TotalPrice() {
        let totalPrice = 0

        Object.keys(this.cart).map(purchaseType => {
            Object.keys(this.cart[purchaseType]).map(flavor => {
                totalPrice += this.cart[purchaseType][flavor] * StoreProducts.GetProductPrice(flavor, purchaseType)
                return false
            })
            return false
        })
        return totalPrice + this.shippingPrice + this.shippingPriceWithoutMethod - this.GetFinalARBalance()
    }

    TotalRetailPrice() {
        let totalPrice = 0

        Object.keys(this.cart).map(purchaseType => {
            Object.keys(this.cart[purchaseType]).map(flavor => {
                if (purchaseType === 'onetime') {
                    totalPrice += this.cart[purchaseType][flavor] * StoreProducts.GetProductPrice(flavor, purchaseType, 'retail')
                } else {
                    totalPrice += this.cart[purchaseType][flavor] * StoreProducts.GetProductPrice(flavor, 'onetime', 'retail')
                }
                return false
            })
            return false
        })

        return totalPrice
    }

    GetStrikePrice(product, itemType) {
        let strikePrice = 0
        if (product) {
            if (itemType === 'onetime') {
                strikePrice = product.price.retail
            } else {
                strikePrice = product.price.after + product.discount.subscription
            }
        }
        return strikePrice
    }

    /**
     * @return {Checkout} Checkout
     */
    CheckOutInstance() {
        if (this.checkoutInstance === null) {
            this.checkoutInstance = new Checkout()
        }
        return this.checkoutInstance
    }

    GetCheckoutForms() {
        return this.forms
    }

    GetShippingForm() {
        return this.shippingForms
    }

    ReArrangeForm() {
        if (!this.CheckOutInstance().CustomCreateAccountWithShippingForms()) {
            let reArrangedForm = []
            this.CheckOutInstance()
                .ArrangeCreateCheckoutForm()
                .map(key => {
                    let item = this.forms.find(field => field.key === key)
                    if (item) {
                        reArrangedForm.push(item)
                    }
                })
            this.forms = reArrangedForm
        }
    }

    SetCheckoutFormValue(key, value) {
        this.forms.map(item => {
            if (item.key === key) {
                runInAction(() => {
                    item.value = value
                })
            }
            return item
        })
        this.shippingForms.map(item => {
            if (item.key === key) {
                runInAction(() => {
                    item.value = value
                })
            }
            return item
        })
    }

    SetItemToForm(newElement) {
        const elem = this.forms.find(item => item.key === newElement.key)
        if (!elem) {
            this.forms.push(newElement)
        }
    }

    GetCheckoutFormValue(key) {
        let item = this.forms.find(item => item.key === key)
        if (item) {
            return item.value
        }
    }

    CheckShippingFormEmpty() {
        // IF IS NOT EMPTY RETURN FALSE
        if (isEmpty(storeFormAddress.GetAddressHistorySelected()) === false) {
            return false
        }

        return this.shippingForms.some(item => {
            const value = item.value

            if (this.CheckOutInstance().ExcludedFieldsShipping().includes(item.key)) {
                return false
            }

            if (this.CheckOutInstance().ExcludedFieldsShippingIfCreateAccount().includes(item.key)) {
                return false
            }

            return isEmpty(value)
        })
    }

    SetCreditCard(itemToSet, value) {
        this.creditCard.map(item => {
            if (item.key === itemToSet.key) {
                if (value.length === 0) {
                    itemToSet.value = ''
                } else {
                    if (item.args) {
                        const regx = get(itemToSet, 'args.regx', null)
                        let regexPass = false
                        if (regx) {
                            const regex = new RegExp(regx, 'i')
                            if (regex.test(value)) {
                                regexPass = true
                            }
                        } else {
                            regexPass = true
                        }

                        const maxLength = get(itemToSet, 'args.maxLength', null)
                        let maxLengthPass = false
                        if (maxLength) {
                            if (value.length <= maxLength) {
                                maxLengthPass = true
                            }
                        } else {
                            maxLengthPass = true
                        }

                        if (regexPass && maxLengthPass) {
                            item.value = value
                        }
                    } else if (itemToSet.key === 'exp_date') {
                        // item.value = moment(value).format('MM/YY')
                        item.value = [value.month, value.year.slice(2, value.year.length)].join('/')
                    } else {
                        item.value = value
                    }
                }
            }

            return item
        })
        this.IsReadyToPlaceOrder()
    }

    GetCreditCardForm() {
        return this.creditCard
    }

    IsReadyToPlaceOrder() {
        let allFilled = []
        this.creditCard.map(item => {
            if (item.value.length > 0) {
                allFilled.push(true)
            } else {
                allFilled.push(false)
            }
            return item
        })

        this.isReadyToPlaceOrder = !ObjectHelpers.hasFalseElements(allFilled)
    }

    GetUuid() {
        return this.uuid
    }

    GetCheckoutErrors() {
        const filteredError = this.checkoutErrors.reduce((acc, current) => {
            return [...acc, this.translate(current)]
        }, [])
        return filteredError
    }

    SetCheckoutErrors(errors = []) {
        this.checkoutErrors = errors
    }

    async CreateUUID(moreStep = false) {
        try {
            runInAction(() => (this.dataReady = false))
            const periodResponse = await FeelGreatAPI.GetPeriod(StoreCountry.Country2())
            const formatType = StoreAuth.IsAuthorized() ? 'shop' : 'enroll'
            const data = {
                type: formatType,
                shipment_packs: JSON.stringify(this.GetCart()),
                period: periodResponse.data.entryPeriod,
                medium: 'Internet',
                agent: 'uFeelGreat',
                platform: getOSName()
            }

            this.period = periodResponse.data.entryPeriod

            if (StoreCountry.CountryLowerCase() === 'singapore') {
                data['shipment_options'] = 'delivery'
            }

            if (StoreReferral.HasReferral()) {
                data.referral_id = StoreReferral.Customer().CustomerId.toString()
            }

            if (StoreAuth.IsAuthorized()) {
                data.ba_status = StoreAuth.userStatus
                data.login_id = StoreAuth.GetId().toString()
                data.login_name = StoreUser.CustomerData().GetNativeName(StoreUser.userData.humanName, 'fullName')
                data.login_name_native = StoreUser.CustomerData().GetNativeName(StoreUser.userData.humanName, 'nativeName')
                data.token = StoreAuth.GetToken()
            }

            const response = await FormatAPIs.createUuid(data, StoreCountry.Country3())
            if (moreStep === false) {
                runInAction(() => {
                    this.dataReady = true
                    this.uuid = response.data.uuid
                })
            } else {
                return {
                    uuid: response.data.uuid,
                    period: data.period
                }
            }
        } catch (e) {
            runInAction(() => {
                this.dataReady = true
            })
            console.error(e)
        }
    }

    async ContinueToPayment(uuid, form) {
        const response = {
            success: false
        }
        try {
            if (StoreAuth.IsAuthorized()) {
                const formatType = 'shop'
                this.CheckOutInstance().FormToFormat(formatType, this.GetShippingForm())

                const data = form ? form : this.InitializeFormShippingData(formatType)

                // console.log('data', data)

                if (uuid) {
                    data.uuid = uuid
                }

                delete data.period
                data.type = 'shop'

                if (this.shippingMethod) {
                    data.shipment_options = this.shippingMethod.api_value
                }

                if (StoreAuth.IsAuthorized()) {
                    data['ba_status'] = StoreAuth.userStatus
                    data['login_id'] = StoreAuth.GetId().toString()
                    data['login_name'] = StoreUser.CustomerData().GetNativeName(StoreUser.userData.humanName, 'fullName')
                    data['login_name_native'] = StoreUser.CustomerData().GetNativeName(StoreUser.userData.humanName, 'nativeName')
                    data['token'] = StoreAuth.GetToken()

                    delete data?.country

                    if (data.confirm_password) {
                        delete data.confirm_password
                    }
                }

                // For Taiwan auto address return some address's zip code be 5 digits but BE need 3 digits
                if (storeCountry.Country2() === 'TW' && data.zip && data.zip.length > 3) {
                    data.zip = data.zip.slice(0, 3)
                }
                data.shipment_packs = JSON.stringify(this.cart)

                // if (storeCountry.Country2() === 'ZA') {
                //     // data.shipment_options = 'delivery'
                //     delete data.state
                //     delete data.zip
                // }

                const updateResponse = await FormatAPIs.putHotFormatV2(data, StoreCountry.Country3())
                if (updateResponse.data.success === false) {
                    response.success = false
                    runInAction(() => {
                        // this.checkoutErrors = updateResponse.data.Message
                        this.checkoutErrors = updateResponse.data.Message_v2
                    })
                } else {
                    this.checkoutErrors = []
                    await storeFreightCharge.SetFreightCharge()
                    response.success = true
                }
            }
        } catch (e) {
            if (StoreAuth.IsAuthorized()) {
                if (!StoreAuth.GetId()) {
                    window.location.reload()
                }
            } else {
                response.success = false
                runInAction(() => (this.dataReady = true))
                console.log(e)
            }
        }
        return response
    }

    InitializeFormShippingData(formatType) {
        const data = {}

        const format = this.CheckOutInstance().GetFormat(formatType)
        Object.keys(format).map(key => {
            if (typeof format[key] === 'string') {
                data[key] = format[key].trim()
            } else {
                data[key] = format[key]
            }
            return key
        })

        data.uuid = this.uuid

        if (data.country) {
            delete data.country
        }

        if (data.confirm_password) {
            delete data.confirm_password
        }

        if (data.fullName) {
            delete data.fullName
        }

        if (StoreAuth.IsAuthorized()) {
            data.token = StoreAuth.GetToken()
        }

        if (data.address && data.address.length === 0) {
            data.address = this.autoAddressSearchText
        }
        if (this.shippingMethod) {
            data.shipment_options = this.shippingMethod.api_value
        }

        return data
    }

    async UpdateQty() {
        try {
            let data = {
                uuid: this.GetUuid(),
                shipment_packs: JSON.stringify(this.cart)
            }
            await FormatAPIs.putAddressAddItemQty(data, StoreCountry.Country3())
        } catch (e) { }
    }

    GoToNextStep(step) {
        runInAction(() => (this.completedActionsLink = [...this.completedActionsLink, ...step]))
    }

    canGoToNextStep(step) {
        if (
            this.completedActionsLink.some(e => {
                return e.includes(step)
            })
        )
            return 1
        return 0
    }

    IsCheckoutLoading() {
        return this.checkoutLoading
    }

    IsCheckoutPreparing() {
        return this.checkoutPreparing
    }

    DataReady() {
        return this.dataReady
    }

    GetCartHistory() {
        return this.cartHistory
    }

    MergeCreateAccountFormToShippingForm(accForm, shippingForm) {
        try {
            const forms = accForm.inputs.concat(shippingForm)
            runInAction(() => {
                this.forms = forms
            })
        } catch (e) {
            console.log(e)
        }
    }

    HasDiscount() {
        const statusAllow = StoreCountry.GetAllowDiscountStatus()
        const baStatus = StoreUser.GetBaStatus()
        const hasReferral = StoreReferral.HasReferral()
        if (statusAllow.includes(baStatus) || (hasReferral && baStatus !== 'C')) {
            return true
        }
        return false
    }

    async CalculateCart(step = '') {
        const baStatus = StoreUser.userData ? StoreUser.CustomerType(StoreUser.userData.type, StoreUser.userData.status).short : null
        let orderSummaryConf = this.CheckOutInstance().GetOrderSummaryConfig()
        let suffix = StoreCountry.GetCurrencyCode()
        let frontProduct = toJS(StoreProducts.GetProducts())
        let frontCheckoutData = toJS(this.GetCart())
        let backStoreFormat = toJS(StoreFormat.GetFormatData())

        let frontCalculate = {
            ...backStoreFormat,
            formatProducts: [],
            formatSaving: {
                member_discount: { suffix: suffix, value: '0' },
                subscription_discount: { suffix: suffix, value: '0' }
            }
        }
        let newItemSet = []
        let ontimeItem = !!frontCheckoutData.onetime ? Object.keys(frontCheckoutData.onetime) : []
        let subscribeItem = !!frontCheckoutData.subscribe ? Object.keys(frontCheckoutData.subscribe) : []
        let onetimeReminder = !!frontCheckoutData.onetimeReminder ? Object.keys(frontCheckoutData.onetimeReminder) : []
        let member_discount = 0
        let subscription_discount = 0
        let all_retail = 0
        let all_member_discount = 0
        let total_price = 0

        let f_member_discount = 0
        let f_sub_discount = 0

        let sumItem = 0
        let sumProductPV = 0

        // ontime Items
        for (let index = 0; index < ontimeItem.length; index++) {
            const itemName = ontimeItem[index]
            let getItem = frontProduct[itemName]['onetime']
            let buyQty = frontCheckoutData.onetime[itemName]
            let itemDiscountPrice = getItem.price.retail * buyQty

            if (this.HasDiscount()) {
                f_member_discount = getItem.price.onetime - getItem.price.member
            } else {
                f_member_discount = getItem.price.onetime - getItem.price.after
            }

            let item_details = {
                baStatus: baStatus,
                item_code: getItem.item_code,
                description: getItem.flavor.text,
                image_url: getItem.image_url,
                item_name: getItem.flavor.text,
                buyQty: buyQty,
                type: 'onetime',
                discount: getItem.discount,
                price: getItem.price,
                price_x_qty: {
                    discount: itemDiscountPrice.toLocaleString(),
                    suffix: suffix,
                    value: (getItem.price.member * buyQty).toLocaleString()
                },
                retail_price_x_qty: {
                    discount: itemDiscountPrice.toLocaleString(),
                    suffix: suffix,
                    value: (getItem.price.retail * buyQty).toLocaleString()
                },
                showDiscount: itemDiscountPrice === itemDiscountPrice
            }

            all_member_discount += f_member_discount * buyQty
            all_retail += getItem.price.retail * buyQty
            total_price += (getItem.price.retail || 0) * buyQty

            sumItem += buyQty

            if (getItem.pv) {
                sumProductPV += getItem.pv * buyQty
            }

            newItemSet.push(item_details)
        }

        // subscribe Items
        for (let index = 0; index < subscribeItem.length; index++) {
            const itemName = subscribeItem[index]
            let getItem = frontProduct[itemName]['subscribe']
            let getItemOnetime = frontProduct[itemName]['onetime']
            let buyQty = frontCheckoutData.subscribe[itemName]
            let itemDiscountPrice = getItem.price.onetime * buyQty

            if (this.HasDiscount()) {
                if (f_member_discount === 0) {
                    f_member_discount = get(getItemOnetime, 'price.onetime') - get(getItemOnetime, 'price.member')
                }
                f_sub_discount = get(getItemOnetime, 'price.member') - get(getItem, 'price.member')
            } else {
                if (f_member_discount === 0) {
                    f_member_discount = get(getItemOnetime, 'price.onetime') - get(getItemOnetime, 'price.after')
                }
                f_sub_discount = get(getItem, 'price.onetime') - get(getItem, 'price.after')
            }

            let item_details = {
                baStatus: baStatus,
                item_code: getItem.item_code,
                description: getItem.flavor.text,
                image_url: getItem.image_url,
                item_name: getItem.flavor.text,
                buyQty: buyQty,
                type: 'subscribe',
                discount: getItem.discount,
                price: getItem.price,
                price_x_qty: {
                    discount: itemDiscountPrice.toLocaleString(),
                    suffix: suffix,
                    value: (getItem.price.member * buyQty).toLocaleString()
                },
                retail_price_x_qty: {
                    discount: itemDiscountPrice.toLocaleString(),
                    suffix: suffix,
                    value: (getItem.price.retail * buyQty).toLocaleString()
                }
            }

            all_member_discount += f_member_discount * buyQty
            subscription_discount += f_sub_discount * buyQty
            all_retail += getItem.price.retail * buyQty
            total_price += (getItem.price.retail || 0) * buyQty

            sumItem += buyQty

            if (getItem.pv) {
                sumProductPV += getItem.pv * buyQty
            }

            newItemSet.push(item_details)
        }

        // onetimeReminder Items
        for (let index = 0; index < onetimeReminder.length; index++) {
            const itemName = onetimeReminder[index]
            let getItem = frontProduct[itemName]['onetimeReminder']
            let buyQty = frontCheckoutData.onetimeReminder[itemName]
            let itemDiscountPrice = getItem.price.retail * buyQty

            if (this.HasDiscount()) {
                f_member_discount = getItem.price.onetime - getItem.price.member
            } else {
                f_member_discount = getItem.price.onetime - getItem.price.after
            }

            let item_details = {
                baStatus: baStatus,
                item_code: getItem.item_code,
                description: getItem.flavor.text,
                image_url: getItem.image_url,
                item_name: getItem.flavor.text,
                buyQty: buyQty,
                type: 'onetimeReminder',
                discount: getItem.discount,
                price: getItem.price,
                price_x_qty: {
                    discount: itemDiscountPrice.toLocaleString(),
                    suffix: suffix,
                    value: (getItem.price.member * buyQty).toLocaleString()
                },
                retail_price_x_qty: {
                    discount: itemDiscountPrice.toLocaleString(),
                    suffix: suffix,
                    value: (getItem.price.retail * buyQty).toLocaleString()
                },
                showDiscount: itemDiscountPrice === itemDiscountPrice
            }

            all_member_discount += f_member_discount * buyQty
            all_retail += getItem.price.retail * buyQty
            total_price += (getItem.price.retail || 0) * buyQty

            sumItem += buyQty

            if (getItem.pv) {
                sumProductPV += getItem.pv * buyQty
            }

            newItemSet.push(item_details)
        }

        member_discount = all_retail - (all_member_discount + total_price)

        // Discount
        if (frontCalculate.formatSaving.member_discount) {
            frontCalculate.formatSaving.member_discount.value = member_discount
        }

        if (frontCalculate.formatSaving.subscription_discount) {
            frontCalculate.formatSaving.subscription_discount.value = '-' + subscription_discount
        }

        // Some Country need to show vat
        this.vat = 0
        if (orderSummaryConf.vat.display) {
            this.vat = orderSummaryConf.vat.value * total_price
        }

        // Some country like MAR
        // how many item that user want to buy
        this.shippingPrice = 0
        this.shippingPriceWithoutMethod = 0

        let referralStatus = null
        try {
            if (toJS(StoreReferral.GetReferral())) {
                referralStatus = toJS(StoreReferral.GetReferral()).shortStatus
            }
        } catch (error) { }
        if (this.shippingMethod && orderSummaryConf.shipping.display && !get(orderSummaryConf, 'shippingWithoutMethod.display')) {
            if ((StoreAuth.userStatus === 'M' || referralStatus) && this.SubTotal() < 2000) {
                if (this.shippingMethod.id === 3) {
                    this.shippingPrice += 50
                } else if (this.shippingMethod.id === 2) {
                    this.shippingPrice += 40
                }
            }

            if (StoreAuth.userStatus === 'A') {
                if (this.shippingMethod.id === 2) {
                    if (sumProductPV < 100) {
                        this.shippingPrice += 40
                    }
                } else if (this.shippingMethod.id === 3) {
                    if (sumProductPV < 150) {
                        this.shippingPrice += 50
                    }
                }
            }
        }

        if (get(orderSummaryConf, 'shippingWithoutMethod.display')) {
            this.shippingPriceWithoutMethod = this.CheckOutInstance().Instance().SpecialAreasDeliveryFeeCondition(step, this.GetSelectedPurchaseOption())
        }

        frontCalculate.formatProducts = newItemSet
        StoreFormat.FrontSideUpdate(frontCalculate)
    }

    GetSumSubscribePrice() {
        let sumSubscribe = 0
        if (StoreFormat.GetFormatData('formatProducts')) {
            let sub = toJS(StoreFormat.GetFormatData('formatProducts'))
            for (let index = 0; index < sub.length; index++) {
                const item = sub[index]
                if (item.type !== 'onetime') {
                    sumSubscribe += this.HasDiscount() ? parseInt(item.price_x_qty.value) : parseInt(item.retail_price_x_qty.value)
                }
            }
        }

        return sumSubscribe
    }

    CheckOutofStockSelectedItem() {
        // return true mean out of stock
        let selectedItem = StoreProducts.GetProduct(this.GetSelectedItem(), this.GetSelectedPurchaseOption())

        if (selectedItem) {
            return selectedItem.qty === 0 || !!selectedItem.system_tags.find(tag => tag.name === 'out_of_stock')
        }
    }
    CheckOutofStockItem(value) {
        // return true mean out of stock
        const targetItem = StoreProducts.GetProduct(value, this.GetSelectedPurchaseOption())
        if (targetItem) {
            return targetItem.qty === 0 || !!targetItem.system_tags.find(tag => tag.name === 'out_of_stock')
        }
    }

    ShowSystemTag({ tagName = '', itemName }) {

        let targetItem = StoreProducts.GetProduct(itemName, this.GetSelectedPurchaseOption())

        if (!targetItem) {
            return null
        }

        return targetItem?.system_tags.find(tag => tag.name === tagName)
    }


    ClearCart() {
        this.cart = {}
    }

    async SetWorldPayReq({ ddcHTML = null, sessionId = null, challengeHTML = null, countPathChange = null }) {
        if (!!ddcHTML) {
            this.ddcHTML = ddcHTML
        }
        if (!!sessionId) {
            this.sessionId = sessionId
        }
        if (!!challengeHTML) {
            this.challengeHTML = challengeHTML
        }
        if (!!countPathChange) {
            this.countPathChange = countPathChange
        }
    }

    async SetWorldPayDimension({ width = null, height = null }) {
        let allDimension = {
            xs: '250x400',
            sm: '390x400',
            md: '500x600',
            lg: '600x400'
        }
        if (width <= 370) {
            this.dimension = allDimension.xs
        } else if (width <= 500) {
            this.dimension = allDimension.sm
        } else if (width <= 700) {
            this.dimension = allDimension.md
        } else if (width <= 900) {
            this.dimension = allDimension.lg
        } else {
            this.dimension = 'fullPage'
        }
    }

    async SetWorldPayResponse(value) {
        this.worldPayResponse = value
    }

    async ResetWorldPayData() {
        this.ddcHTML = null
        this.sessionId = null
        this.challengeHTML = null
        this.countPathChange = null
        this.worldPayResponse = false
        this.countPathChange = 0
        this.dimension = '390x400'
    }

    SetLoginToPurchase(value) {
        this.isLoginToPurchase = value
    }

    GetLoginToPurchase() {
        return this.isLoginToPurchase
    }

    SetIsLoginOnly(value) {
        this.IsLoginOnly = value
    }

    GetIsLoginOnly() {
        return this.IsLoginOnly
    }

    GetPaymentMethod() {
        return this.CheckOutInstance().GetPaymentMethod()
    }

    SetPaymentMethod(value) {
        this.CheckOutInstance().SetPaymentMethod(value)
    }

    SetErrors(value) {
        this.paymentErrors = value
    }

    GetPaymentErrors() {
        return this.paymentErrors
    }

    SetPeriod(value) {
        this.period = value
    }

    CheckSubscribeInCart() {
        return this.cart.hasOwnProperty('subscribe') && Object.keys(this.cart?.subscribe).length > 0
    }

    constructor() {
        makeAutoObservable(this)
    }
}

const storeCheckout = new StoreCheckout()
export default storeCheckout
