import axios from 'axios'
import { ServerEnv } from 'Configs/ServerEnv'
import StoreCountry from 'GlobalStores/Cores/County/StoreCountry'
import StorePaymentStep from 'GlobalStores/Cores/Elements/StorePaymentStep'
import { ObjectHelpers } from 'Helpers/ObjectHelpers'
import { get, isEmpty } from 'lodash'
import { makeAutoObservable, runInAction, toJS } from 'mobx'
import { FeelGreatAPI } from 'Services/FeelGreat/APIs'
import TrackingEvents from 'Services/Tracker/TrackingEvents'
import Payment from '../Payment'
import { PaymentAPIs } from '../PaymentServices/PaymentAPI'
import StoreAuth from 'GlobalStores/User/StoreAuth'
import storeCredit from 'GlobalStores/StoreCredit'
import States from 'Components/Elements/Forms/StatesModal/Country/HongkongStates.json'
import StoreTranslate from 'GlobalStores/Cores/Translator/StoreTranslate'
import storeCheckout from 'GlobalStores/Checkout/StoreCheckout'
import storeFormat from 'GlobalStores/Format/StoreFormat'
import StoreProducts from 'GlobalStores/StoreProducts'

class Hongkong {
    autoAddressDisplayKeys = ['street', 'locality', 'postal_code']
    autoAddressKeyToForm = {
        street: 'address1',
        locality: 'district',
        administrative_area: 'state',
        postal_code: 'zip_code'
    }

    chainAddress = {
        isChainAddress: false,
        groupAddress: []
    }

    uuid = ''

    toFormat = {
        enroll: {
            uuid: this.GetUuid(),
            firstname: '',
            lastname: '',
            address1: '',
            district: '',
            email: '',
            phone: '',
            password: ''
            // password1: '',
            // password2: ''
        },
        enrollWithReferral: {
            uuid: this.GetUuid(),
            firstname: '',
            lastname: '',
            address1: '',
            district: '',
            email: '',
            phone: '',
            password1: '',
            password2: ''
        },
        shop: {
            uuid: this.GetUuid(),
            // firstname: '',
            // lastname: '',
            address1: '',
            district: '',
            email: ''
        }
    }

    formShop = {
        firstname: '',
        lastname: '',
        // email: '',
        // phone: '',
        address1: '',
        district: ''

        //
    }

    createAccountWithShippingAddress = {
        type: 'enroll',
        referral_id: '',
        // firstname: '',
        // lastname: '',
        firstname_en: '',
        lastname_en: '',
        email: '',
        phone: '',
        password: '',
        address1: '',
        district: '',
        period: ''
    }

    ExcludedFieldsShippingIfCreateAccount = ['fullName']

    ExcludedFieldsShipping = []

    mapFormKeyToFormatKey = {
        address1: 'address1',
        fullName: 'fullname'
        // firstname: 'firstname_en',
        // lastname: 'lastname_en'
    }

    ArrangeCreateCheckoutForm = ['firstname', 'lastname', 'email', 'password', 'phone', 'country', 'address1', 'district']

    PaymentStep = {
        payment: 0,
        createOrder: 0,
        autoship: -2,
        updateLog: 0
    }

    // breadcrumbOption = [
    //     {
    //         caption: 'customise',
    //         link: '/products',
    //         paramsRequire: false
    //     },
    //     {
    //         caption: 'signup_shipping',
    //         link: '/checkout/create-account',
    //         paramsRequire: true,
    //         authCaption: 'shipping',
    //         authLink: '/checkout/shipping'
    //     },
    //     {
    //         caption: 'billing',
    //         link: '/checkout/payment',
    //         paramsRequire: true
    //     },
    //     {
    //         caption: 'purchase',
    //         link: '/checkout/review',
    //         paramsRequire: true
    //     }
    // ]
    breadcrumbOption = [
        {
            caption: 'customise',
            link: '/products',
            paramsRequire: false
        },
        {
            caption: 'signup_shipping',
            link: '/checkout/create-account',
            paramsRequire: true,
            authCaption: 'shipping',
            authLink: '/checkout/shipping'
        },
        {
            caption: 'purchase',
            link: '/checkout/payment',
            paramsRequire: true
        }
    ]

    shippingMethods = [
        {
            id: 1,
            api_value: 'pickup',
            title: 'Pickup, Casablanca Office',
            dict_key: 'pickup_casablanca_office',
            for_status: [],
            options: [
                {
                    id: 1,
                    title: 'Credit card',
                    dict_key: 'creditcard',
                    for_status: []
                }
            ]
        },
        {
            id: 2,
            api_value: 'ctm',
            title: 'CTM, Delivery to Centers',
            dict_key: 'ctm_delivery_to_centers',
            for_status: [],
            options: []
        },
        {
            id: 3,
            api_value: 'homedelivery',
            title: 'Home Delivery',
            dict_key: 'home_delivery',
            for_status: [],
            options: [
                {
                    id: 1,
                    title: 'Credit card',
                    dict_key: 'creditcard',
                    for_status: []
                },
                {
                    id: 2,
                    title: 'Cash on Delivery',
                    dict_key: 'cash_on_delivery',
                    descripion: {
                        title: 'Pay when you receive',
                        dict_key: 'pay_when_you_receive'
                    },
                    for_status: ['M']
                }
            ]
        }
    ]

    orderSummaryConfig = {
        subTotal: {
            display: true,
            dict_key: 'subtotal'
        },
        shipping: {
            display: false,
            dict_key: 'shipping'
        },
        productCredit: {
            display: true,
            dict_key: 'product_credit',
            icon: false,
            allowToUse: []
        },
        vat: {
            display: false,
            dict_key: 'vat',
            value: 0.2 // 20%
        },
        shippingWithoutMethod: {
            display: true,
            displayStep: ['payment'],
            dict_key: 'shipping'
        }
    }

    landingLayouts = [
        {
            nameComponent: 'banner',
            titleContent: 'mainBanner',
            style: {}
        },
        {
            nameComponent: 'ourApproach',
            titleContent: 'ourApproach',
            style: {
                minHeight: '0px'
            }
        },
        {
            nameComponent: 'theProducts',
            titleContent: 'theProducts',
            style: {
                backgroundColor: '#F1F6FB'
            }
        },
        {
            nameComponent: 'thePractice',
            titleContent: 'thePractice',
            style: {}
        },
        {
            nameComponent: 'howItWorks',
            titleContent: 'howItWorks',
            style: {
                backgroundColor: '#FBF7F1'
            }
        },
        {
            nameComponent: 'whatExpertsSay',
            titleContent: 'expertsSay',
            style: {}
        },
        {
            nameComponent: 'realResults',
            titleContent: 'realResults',
            style: {
                backgroundColor: '#F2F9F8'
            }
        }
    ]

    productLayouts = [
        {
            nameComponent: 'productDetailSelection',
            titleContent: 'productDetailSelection',
            style: {}
        },
        {
            nameComponent: 'theProducts',
            titleContent: 'theProducts',
            style: {}
        },
        {
            nameComponent: 'frequentlyAskedQuestions',
            titleContent: 'questions',
            style: {
                backgroundColor: '#F6F9FC'
            }
        }
    ]

    MapAutoAddressToForm(form, autoAddressData) {
        const swappedValuesAsKeys = ObjectHelpers.swapKeysAndValues(this.autoAddressKeyToForm)
        return form.map(item => {
            if (swappedValuesAsKeys[item.key]) {
                if (autoAddressData.data) {
                    item.value = autoAddressData.data[swappedValuesAsKeys[item.key]]
                }
            }

            return item
        })
    }

    FormToFormat(type, form) {
        form.map(item => {
            if (this.mapFormKeyToFormatKey[item.key]) {
                if (this.toFormat[type]) {
                    runInAction(() => {
                        this.toFormat[type][this.mapFormKeyToFormatKey[item.key]] = item.value
                    })
                }
            } else {
                if (this.toFormat[type]) {
                    runInAction(() => {
                        this.toFormat[type][item.key] = item.value
                    })
                }
            }
            return item
        })
    }

    SetUuid(value) {
        this.uuid = value
    }

    GetUuid() {
        return this.uuid
    }

    GetFormat(type) {
        return this.toFormat[type]
    }

    GetPaymentStep() {
        return this.PaymentStep
    }

    GetBreadcrumbOption() {
        return this.breadcrumbOption
    }

    GetShippingMethod() {
        return this.shippingMethods
    }

    SetPaymentStep(key, value) {
        this.PaymentStep[key] = value
    }

    GetOrderSummaryConfig() {
        return this.orderSummaryConfig
    }

    GetLandingLayout() {
        return this.landingLayouts
    }

    GetProductLayout() {
        return this.productLayouts
    }

    StatesList() {
        let items = []
        get(States, StoreTranslate.CurrentLanguage(), []).map(raw => {
            items.push({
                text: raw,
                value: raw,
                html: `<span>${raw}</span>`
            })
        })
        return items
    }

    AddressOptionList(key) {
        let items = []
        get(States, StoreTranslate.CurrentLanguage(), []).map(raw => {
            items.push({
                text: raw,
                value: raw,
                html: `<span>${raw}</span>`
            })
        })
        return items
    }

    SpecialAreasDeliveryFeeCondition(step) {
        if (!this.orderSummaryConfig.shippingWithoutMethod.displayStep.includes(step)) return 0

        if (isEmpty(storeFormat.GetFormatData('formatProducts'))) return 0

        let weight = storeFormat.GetFormatData('formatProducts').reduce((totalWeight, formatProduct) => {
            const products = toJS(StoreProducts.GetProducts())
            const flavor = Object.keys(products).find(key => products[key][formatProduct.type].item_code === formatProduct.item_code)
            const product = products[flavor][formatProduct.type]
            const weight = get(product, 'weight.value', 0)

            let productQty = 0
            let productWeight = 0
            try {
                productQty = Number(toJS(storeCheckout.GetCart())[formatProduct.type][flavor])
                if (isNaN(productQty)) {
                    productQty = 0
                }

                productWeight = Number(weight) * productQty
                return totalWeight + productWeight
            } catch (error) {
                // console.log('fetch some error => ', error)
                //
            }
        }, 0)
        const additionalWeightFee = 7 // Fee for each additional kg above 5 kg

        let totalPrice = 0

        const districtValue = storeCheckout.GetShippingForm().find(item => item.key === 'district' && item.value)

        if (!districtValue || !storeCheckout.TotalPrice()) return totalPrice

        const deliveryFee = districtValue.value.match(/Macau|澳門/) ? 200 : 0 // Delivery fee for orders below 3000

        if (+storeCheckout.TotalPrice() < 3000) {
            if (weight <= 5) {
                totalPrice = deliveryFee
            } else {
                const additionalWeight = weight - 5
                const additionalFee = additionalWeight * additionalWeightFee
                totalPrice = additionalFee + deliveryFee
            }
        }

        if (districtValue.value.match(/Discover Bay|Lantau Island Region|Tung Chung|愉景灣|大嶼山地區|東涌/)) {
            totalPrice += 40
        }

        if (districtValue.value.match(/Sha Tau Kok Region|沙頭角地區/)) {
            totalPrice += 100
        }

        return totalPrice
    }

    /**
     *
     * @param {*} uuid
     * @param {object} detailPage { products, detailPage, isSubscribe, isEnroll, type}
     * @param {*} CreditCardEncrypted
     */
    async SubmitPayment(uuid, detailPage, CreditCardEncrypted, products) {
        try {
            const Pay = new Payment(uuid, detailPage)
            const responsePreparePayment = await Pay.PreparePaymentData()

            const allowCreditEnable = StoreAuth.IsAllowCredit()

            if (allowCreditEnable && storeCredit.GetCreditData()?.creditUsed > 0) {
                Pay.hydra.transactions = {
                    items: [CreditCardEncrypted, storeCredit.ProductCreditUsed()]
                }
            } else {
                Pay.hydra.transactions = {
                    items: [CreditCardEncrypted]
                }
            }

            await Pay.PostToHydra()

            Pay.SendEmail()
            Pay.SendSms()

            await Pay.UpdateToLog()

            const responsePaymentDetail = await PaymentAPIs.GetPaymentDetail(responsePreparePayment.data.data.payment_id)
            runInAction(() => {
                StorePaymentStep.PaymentDone = true
            })
            try {
                TrackingEvents.PurchaseEvent({
                    transaction_id: responsePreparePayment.data.data.payment_id,
                    value: Pay.ushop.total_price,
                    currency: 'AUD',
                    category: Pay.ushop.shipment_purchas_option,
                    items: products
                })
            } catch (e) {
                console.error('TrackingEvents.PurchaseEvent', e)
            }
            return {
                success: true,
                payment_id: responsePreparePayment.data.data.payment_id,
                order_number: responsePaymentDetail.data.order_number
            }
        } catch (error) {
            console.error(error)
            runInAction(() => {
                StorePaymentStep.PaymentDone = true
            })

            return { success: false }
        }
    }

    async CreateAccount(uuid, accForm, shippingForm, referral, cartItems) {
        try {
            const forms = Object.assign(this.createAccountWithShippingAddress, accForm)
            const returnShippingAddress = {}
            shippingForm.map(item => {
                if (this.ExcludedFieldsShippingIfCreateAccount.includes(item.key)) {
                    return false
                }
                if (this.mapFormKeyToFormatKey[item.key]) {
                    forms[this.mapFormKeyToFormatKey[item.key]] = item.value
                    returnShippingAddress[this.mapFormKeyToFormatKey[item.key]] = item.value
                } else {
                    forms[item.key] = item.value
                    returnShippingAddress[item.key] = item.value
                }
            })

            if (referral) {
                forms.referral_id = referral
            } else {
                delete forms.referral_id
            }

            delete forms.country

            const periodResponse = await FeelGreatAPI.GetPeriod('HK')
            forms.period = periodResponse.data.entryPeriod
            forms.uuid = uuid
            forms.shipment_packs = JSON.stringify(cartItems)
            const url = `https://${ServerEnv.MemberCalls2()}.unicity.com/hot/formatV2/HKG/feelGreat/createAccount${ServerEnv.ENV === 'DEV' ? '?dev=1' : ''}`
            const response = await axios.post(url, forms)

            return {
                ...response.data,
                form: forms
            }
        } catch (e) {
            console.error(e)

            return {
                success: false
            }
        }
    }

    constructor() {
        StorePaymentStep.SetPaymentSteps(this.PaymentStep)
        makeAutoObservable(this)
    }
}

export default Hongkong
