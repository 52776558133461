import { Box, Fade, Modal, Stack, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { ReactComponent as ProfileIcon } from './Assets/profile.svg'
import { T } from 'GlobalStores/Cores/Translator/useTranslate'
import storeAuth from 'GlobalStores/User/StoreAuth'
import Login from '../Login/Login'

const AlreadyHaveAccount = observer(props => {
    const [openLoginModal, setOpenLoginModal] = useState(false)
    return (
        <Stack
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
                alignItems: 'center',
                backgroundColor: '#FFF1DC',
                padding: '10px 0px',
                borderRadius: '6px',
            }}>
            <Box
                sx={{
                    paddingLeft: '16px',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '12px'
                }}>
                <Box>
                    <ProfileIcon />
                </Box>
                <Typography
                    variant="caption"
                    sx={{
                        color: '#003B6F',
                        fontSize: '12px',
                        fontWeight: '400'
                    }}>
                    <T>Already_have_an_account</T>
                </Typography>
            </Box>
            <Box
                sx={{
                    paddingRight: '16px',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '12px'
                }}>
                <Typography
                    variant="caption"
                    sx={{
                        color: '#003B6F',
                        fontSize: '12px',
                        fontWeight: '700',
                        cursor: 'pointer',
                        textDecoration: 'underline'
                    }}
                    onClick={() => setOpenLoginModal(true)}
                >
                    <T>login</T>
                </Typography>
            </Box>

            <Modal
                open={openLoginModal}
                onClose={() => {
                    if (storeAuth.loadingLogin) return
                    setOpenLoginModal(false)
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Fade in={openLoginModal}>
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 'calc(50% - 40px)',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            padding: '0px',
                            '&:focus-visible': {
                                outline: 'none'
                            }
                        }}>
                        <Login showFooter={false} setOpenLoginModal={setOpenLoginModal} />
                    </Box>
                </Fade>
            </Modal>
        </Stack>
    )
})

export default AlreadyHaveAccount