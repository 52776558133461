import axios from 'axios'
import { ServerEnv } from 'Configs/ServerEnv'
import StoreCountry from 'GlobalStores/Cores/County/StoreCountry'
import storeAuth from 'GlobalStores/User/StoreAuth'
import StoreAuth from 'GlobalStores/User/StoreAuth'
import { last } from 'lodash'
import httpHelper from 'Services/HttpHelper'
import HttpHelper from 'Services/HttpHelper'

const GetOrderHistory = (baId, token) => {
    const queryStrings = [`baId=${baId}`, `ushopCountryCode=${StoreCountry.Country3()}`]

    if (ServerEnv.ENV === 'DEV') {
        queryStrings.push(`isQa=1`)
    }

    const url = HttpHelper.Proxy({
        originalUrl: `https://${ServerEnv.MemberCalls2()}.unicity.com/etlV2/ordersHistory?${queryStrings.join('&')}`
    })

    const headers = {
        'Content-Type': 'application/json',
        'authorization-hydra': `Bearer ${token}`
    }

    return axios.get(url, {
        headers: headers
    })
}

const GetOrderHistoryDetail = (token, orderRef) => {
    let ref = last(orderRef.split('/'))

    const queryStrings = [`orderRef=${ref}`, `ushopCountryCode=${StoreCountry.Country3()}`]

    if (ServerEnv.ENV === 'DEV') {
        queryStrings.push(`isQa=1`)
    }

    const url = HttpHelper.Proxy({
        originalUrl: `https://${ServerEnv.MemberCalls2()}.unicity.com/etlV2/orderDetail?${queryStrings.join('&')}`
    })

    const headers = {
        'Content-Type': 'application/json',
        'authorization-hydra': `Bearer ${token}`
    }

    return axios.get(url, {
        headers: headers
    })
}

const GetSubscriptions = token => {
    const url = HttpHelper.Proxy({ originalUrl: `${ServerEnv.Hydra()}/customers/me/autoorders` })

    return axios.get(url, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    })
}

const GetSubscriptionsItems = (token, href) => {
    const url = HttpHelper.Proxy({ originalUrl: href })

    return axios.get(url, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    })
}

const GetAddress = (shipmentOptions, shipToAddress) => {
    let data = {
        shipment_options: shipmentOptions,
        shipToAddress: shipToAddress
    }

    const url = HttpHelper.Proxy({ originalUrl: `https://${ServerEnv.MemberCalls2()}.unicity.com/hot/format/${StoreCountry.Country3()}/shop/address-format` })

    return axios.post(url, data, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

const Creditcardaliases = (token, userCountry) => {
    const url = HttpHelper.Proxy({ originalUrl: `${ServerEnv.Hydra()}/customers/me/creditcardaliases/${userCountry}` })

    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    }

    return axios.get(url, { headers })
}

const GetPeriod = (countryCode2Alpha, joinPeriod) => {
    const url = HttpHelper.Proxy({ originalUrl: `https://${ServerEnv.MemberCalls2()}.unicity.com/topup/period` })

    const data = {
        country: countryCode2Alpha,
        system: 'AO',
        joinPeriod: joinPeriod || ''
    }

    return axios.post(url, data, {
        Headers: {
            'Content-Type': 'applicaton/json'
        }
    })
}

const GetPaymentDetail = (paymentId, baId) => {
    const data = {
        payment_id: paymentId,
        type: baId ? 'shop' : 'enroll',
        ba_id: baId || ''
    }

    const url = HttpHelper.Proxy({ originalUrl: `https://${ServerEnv.MemberCalls2()}.unicity.com/hot/payment_router/detail/hydra` })

    return axios.post(url, data, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

const SendEmailV3 = (data, countryCode3Alpha) => {
    let originalUrl = `https://${ServerEnv.MemberCalls2()}.unicity.com/email-api/email-v3/public/${countryCode3Alpha}/feelGreat`

    switch (countryCode3Alpha) {
        case 'AUS_WILL_LVE':
            originalUrl = `https://${ServerEnv.MemberCalls2()}.unicity.com/email-api/email-fg-journey/public/${countryCode3Alpha}/feelGreat`
            break

        default:
            delete data['baStatus']
            delete data['action']
            delete data['purchaseType']

            originalUrl = `https://${ServerEnv.MemberCalls2()}.unicity.com/email-api/email-v3/public/${countryCode3Alpha}/feelGreat`
            break
    }

    const emailApi = HttpHelper.Proxy({
        originalUrl: originalUrl
    })

    axios
        .post(emailApi, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => {})
        .catch(error => console.error(error))
}

const SendEmailV4 = (data, countryCode3Alpha) => {
    let originalUrl = `https://${ServerEnv.MemberCalls2()}.unicity.com/email-api/email-v4/public/${countryCode3Alpha}/feelGreat`

    switch (countryCode3Alpha) {
        case 'AUS_WILL_LVE':
            originalUrl = `https://${ServerEnv.MemberCalls2()}.unicity.com/email-api/email-fg-journey/public/${countryCode3Alpha}/feelGreat`
            break

        default:
            delete data['baStatus']
            delete data['action']
            delete data['purchaseType']

            originalUrl = `https://${ServerEnv.MemberCalls2()}.unicity.com/email-api/email-v4/public/${countryCode3Alpha}/feelGreat`
            break
    }

    const emailApi = HttpHelper.Proxy({
        originalUrl: originalUrl
    })

    axios
        .post(emailApi, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => {})
        .catch(error => console.error(error))
}

const CancelSubscription = (data, token) => {
    const url = HttpHelper.Proxy({ originalUrl: `https://${ServerEnv.MemberCalls2()}.unicity.com/proxy/autoship-v2/delete` })

    return axios.post(url, data, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    })
}

const SkipDelivery = (data, token) => {
    const url = HttpHelper.Proxy({ originalUrl: `https://${ServerEnv.MemberCalls2()}.unicity.com/proxy/autoship/skip` })

    return axios.post(url, data, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    })
}

const GetCreditAndArBalance = (baId, token) => {
    const queryStrings = [`baId=${baId}`, `ushopCountryCode=${StoreCountry.Country3()}`]

    if (ServerEnv.ENV === 'DEV') {
        queryStrings.push(`isQa=1`)
    }
    const url = HttpHelper.Proxy({
        originalUrl: `https://${ServerEnv.MemberCalls2()}.unicity.com/etlV2/credit/availableBalance?${queryStrings.join('&')}`
    })

    return axios.get(url, {
        headers: {
            'Content-Type': 'application/json',
            'authorization-hydra': `Bearer ${token}`
        }
    })
}

const FormatAddress = data => {
    const url = HttpHelper.Proxy({
        originalUrl: `https://${ServerEnv.MemberCalls2()}.unicity.com/hot/format/${StoreCountry.Country3()}/feelGreat/address-format`
    })

    return axios.post(url, data, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

const EditSubscriptions = (data, token) => {
    const url = HttpHelper.Proxy({
        originalUrl: `https://${ServerEnv.MemberCalls2()}.unicity.com/proxy/${StoreCountry.Country3()}/subscriptions/edit`
    })

    return axios.post(url, data, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    })
}

const SendNowSubscriptions = (data, token) => {
    const url = HttpHelper.Proxy({
        originalUrl: `https://${ServerEnv.MemberCalls2()}.unicity.com/proxy/autoship/order`
    })

    return axios.post(url, data, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    })
}

const CreatePaymentDataForHostedGateway = data => {
    const url = `https://${ServerEnv.MemberCalls2()}.unicity.com/hot/payment_router/proxy/insert/${StoreCountry.Country3()}`

    return axios.post(url, data, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

const GatewayPaymentDetail = gatewayRefId => {
    const url = `https://${ServerEnv.MemberCalls2()}.unicity.com/hot/payment_router/detail/proxy`
    const data = {
        gateway_ref_id: gatewayRefId,
        is_referral_code: true
    }

    return axios.post(url, data)
}

const GetGeoIPAccessAndBAID = ({ type = 'feelGreat', countryCode3Digit = StoreCountry.Country3(), baID = StoreAuth.GetId() }) => {
    const url = `https://member-calls2.unicity.com/geoLocation/checkAllow/?platform=${type}&countryCode=${countryCode3Digit}&baId=${baID}`

    return axios.get(url)
}

const GetCityList = (countryCode3Digit = StoreCountry.Country3()) => {
    const url = `https://${ServerEnv.MemberCalls2()}.unicity.com/unishop-fn-misc/city/country/${countryCode3Digit}`

    return axios.get(url)
}

const CreditCardCheck = data => {
    const url = HttpHelper.Proxy({
        originalUrl: `https://${ServerEnv.MemberCalls2()}.unicity.com/validate/creditCard/checkValidate`
    })

    return axios.post(url, data, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

const HydraWorldPayReqForm = (URL, CreditcardNumber) => {
    const url = HttpHelper.Proxy({ originalUrl: `${URL}/ddc` })
    return axios.post(
        url,
        JSON.stringify({
            bin: CreditcardNumber
        })
    )
}

const deleteCache = baId => {
    const url = `https://${ServerEnv.MemberCalls2()}.unicity.com/etlV2/cache/clearAll?baId=${baId}`
    return axios.delete(url)
}

const GetSubscriptionConfig = (countryCode3Digit = StoreCountry.Country3()) => {
    const url = `https://${ServerEnv.MemberCalls2()}.unicity.com/settings/subscriptionSettings/${countryCode3Digit}`

    return axios.get(url)
}

const GetAddressHistory = (countryCode3Digit = StoreCountry.Country3()) => {
    let token = StoreAuth?.GetToken()
    let baId = StoreAuth?.GetId()

    const url = `https://${ServerEnv.MemberCalls2()}.unicity.com/etlV2/addressHistory?baId=${baId}&ushopCountryCode=${countryCode3Digit}`

    const headers = {
        'authorization-hydra': `Bearer ${token}`
    }
    return axios.get(url, { headers: headers })
}

const saveAddressHistory = (shipToName, shipToAddress, countryCode3Digit = StoreCountry.Country3()) => {
    let token = StoreAuth?.GetToken()
    let baId = StoreAuth?.GetId()

    const url = `https://${ServerEnv.MemberCalls2()}.unicity.com/etlV2/addressHistory?baId=${baId}&ushopCountryCode=${countryCode3Digit}`

    return axios.post(
        url,
        {
            shipToName: { ...shipToName },
            shipToAddress: { ...shipToAddress }
        },
        {
            headers: {
                'Content-Type': 'application/json',
                'authorization-hydra': `Bearer ${token}`
            }
        }
    )
}

const ValidateTaxInvoice = (postData, isValidateTaxInvoice) => {
    // validateTaxInvoice=1
    const url = `https://${ServerEnv.MemberCalls2()}.unicity.com/hot/formatV2/${StoreCountry.Country3()}/feelGreat/address?validateTaxInvoice=1`

    return axios.put(url, postData)
}

const setPdpaStatus = data => {
    const url = `https://${ServerEnv.MemberCalls2()}.unicity.com/pdpa/dataCheckbox`
    return httpHelper.Post({
        url: url,
        data,
        config: {
            headers: {
                'Content-Type': 'application/json',
                'authorization-hydra': storeAuth.GetToken()
            }
        }
    })
}

const getPdpaStatus = baId => {
    const url = `https://${ServerEnv.MemberCalls2()}.unicity.com/pdpa/data?baId=${baId}`
    return httpHelper.Get({
        url: url,
        config: {
            headers: {
                'Content-Type': 'application/json'
            }
        }
    })
}

const getFeelGreatProductsV3 = (balStatus = null) => {
    let queryStrings = []
    if (balStatus) {
        queryStrings.push(`baStatus=${balStatus}`)
    }

    const url = `https://${ServerEnv.MemberCalls2()}.unicity.com/products-v2/feelGreat/v3/publish/${StoreCountry.Country3()}${
        queryStrings.length ? '?' + queryStrings.join('&') : ''
    }`

    return axios.get(HttpHelper.Proxy({ originalUrl: url }), {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

const getReferralCodes = token => {
    const url = 'https://hydra.unicity.net/v5a/customers/me/referralcodes'
    return axios.get(HttpHelper.Proxy({ originalUrl: url }), {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
}

const getFooter = () => {
    const url = `https://${ServerEnv.MemberCalls2()}.unicity.com/footer/data/public/${StoreCountry.Country3()}`
    return axios.get(HttpHelper.Proxy({ originalUrl: url }))
}

export const FeelGreatAPI = {
    GetOrderHistory,
    GetOrderHistoryDetail,
    GetPeriod,
    GetPaymentDetail,
    Creditcardaliases,
    GetSubscriptions,
    GetAddress,
    SendEmailV3,
    CancelSubscription,
    SkipDelivery,
    GetCreditAndArBalance,
    FormatAddress,
    EditSubscriptions,
    SendNowSubscriptions,
    CreatePaymentDataForHostedGateway,
    GatewayPaymentDetail,
    GetGeoIPAccessAndBAID,
    GetCityList,
    CreditCardCheck,
    GetSubscriptionsItems,
    HydraWorldPayReqForm,
    deleteCache,
    GetSubscriptionConfig,
    GetAddressHistory,
    saveAddressHistory,
    ValidateTaxInvoice,
    setPdpaStatus,
    getPdpaStatus,
    getFeelGreatProductsV3,
    getReferralCodes,
    getFooter,
    SendEmailV4
}
